import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
const DeleteMyAccount = () => {
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
    const [waiting, setWaiting] = useState(false)
 console.log(email, note)
  const handleSubmit = (e) => {
    if(!email) {
        Swal.fire({icon: 'warning', title: 'Please enter an email address.',text: "", timer: 2000, })

        return;
    }
    e.preventDefault();
    const data = {
      email,
      note,
    };
    setWaiting(true)
    axios.post('/api/v2/delete-account-request', data).then((res) => {
        if (res.status === 200) {
            Swal.fire({icon: 'success', title: 'Request sent.',text: "Please allow up to 5 business days for the account to be deleted.", timer: 2000, })
            setEmail('');
            setNote('');
            setWaiting(false)
        }
        }).catch((err) => {
            Swal.fire({icon: 'error', title: 'Error',text: "There was an error sending your request. Please try again later.", timer: 2000, })
            setWaiting(false)
        }
    );
  };

  return (
    <div className='container ' style={{maxWidth: '800px', margin: 'auto', padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem',justifyContent: 'center'}} >
      <h2 className='text-center'>Request Account Deletion</h2>
      <p>Please enter your email and an optional note to request the deletion of your account. Your request will be sent to contact@bridgecoreutah.com.</p>
      <p>Please allow up to 5 business days for the account to be deleted.</p>
      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', maxWidth: '400px', margin: 'auto'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <label>Note (optional):</label>
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <Button type="submit" disabled={waiting}>{waiting ? "Submitting":"Submit"}</Button>
      </form>
    </div>
  );
};

export default DeleteMyAccount;