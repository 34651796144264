import React from "react";
import "./Footer.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { withRouter } from "react-router";
import { useUser } from "../../../UserContext";
import { Link } from "react-router-dom";

//// ONLY THE FIRST NAV LNKS WILL WORK
function Footer({ user, history }) {
  // let user = useUser();
  const goToPortal = () => {
    if (user.currentUser) {
      if (user.currentUser.role === "admin") {
        history.push("/admin");
      } else if (user.currentUser.user_id && !user.currentUser.role) {
        history.push("/portal");
      } else {
        //todo: route to employee portal when more rules are introduced in the future.
        history.push("/login");
      }
    }
  };
  return (
    <Container className="footer-container p-3">
      <Row className='mb-3'>
        <Col xs="12" className='d-flex justify-content-center text-dark'>
          <a
            href="https://www.bbb.org/us/ut/highland/profile/escrow-services/bridge-core-llc-1166-90042290/#sealclick"
            target="_blank"
            rel="nofollow"
          >
            <img
              src="https://seal-utah.bbb.org/seals/blue-seal-293-61-blacktxt-bbb-90042290.png"
              style={{ border: 0 }}
              alt="Bridge Core, LLC BBB Business Review"
            />
          </a>
        </Col>
      </Row>
      <Row>
        <Col
          xs="12"
          sm="6"
          lg="3"
          className="mb-2 d-flex align-items-center justify-content-center"
        >
          <a href="/">
            <img
              className="footer-logo"
              src="/images/BC-logo.png"
              alt="Bridge Core logo"
            />
          </a>
        </Col>

        <Col xs="12" sm="6" lg="3" className="mb-2">
          <Nav className="flex-column">
            <p className="text-primary">
              <strong>NAVIGATION</strong>
            </p>
            <Link className="footer-links text-dark" to="/promise">
              <span>Our Promise</span>
            </Link>
            <Link className="footer-links text-dark" to="/escrow">
              <span>Escrow Services</span>
            </Link>
            <Link className="footer-links text-dark" to="/join">
              <span>Join the Family</span>
            </Link>
            <Link className="footer-links text-dark" to="/companies">
              <span>Find a Contractor</span>
            </Link>
          </Nav>
        </Col>

        {/* <Col xs="12" sm="6" lg="3" className="mb-2">
          <Nav className="flex-column ">
            <p className="text-primary">
              <strong>CONNECT</strong>
            </p>
            
            <Nav.Link className="footer-links text-dark mb-1">
              Instagram
            </Nav.Link>
            <Nav.Link className="footer-links text-dark mb-1">
              Facebook
            </Nav.Link>
            <Nav.Link className="footer-links text-dark mb-1">Twitter</Nav.Link>
            <Nav.Link className="footer-links text-dark mb-1">
              LinkedIn
            </Nav.Link>
          </Nav>
        </Col> */}

        <Col xs="12" sm="6" lg="3" className="mb-2">
          <Nav className="flex-column">
            <p className="text-primary">
              <strong>GENERAL</strong>
            </p>
            <div
              className="footer-links text-dark"
              onClick={() => goToPortal()}
            >
              <span className="footer-links text-dark mb-1" to="/">
                Account
              </span>
            </div>

            <Link className="footer-links text-dark" to="/contact">
              <span className=" mb-1" to="/">
                Contact Us
              </span>
            </Link>
            
            <Link className="footer-links text-dark" to="/delete-my-account">
              <span className=" mb-1" to="/">
                Delete My Account
              </span>
            </Link>
            
            <Link className="footer-links text-dark" to="/privacy">
              <span className=" mb-1" to="/">
                Privacy Policy
              </span>
            </Link>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(Footer);
